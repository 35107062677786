import { Color, ColorCSS, DimensionPx, FontSizeEm } from "../utils/constants";
import { mergeStyles } from "../utils/react";

export function Section(
  props: React.PropsWithChildren<{
    icon?: React.ReactNode;
    isExpanded?: boolean;
    onClick?: () => unknown;
    title: string;
  }>
) {
  return (
    <div
      onClick={props.onClick}
      style={{
        alignItems: "start",
        display: "flex",
        flexDirection: "row",
        gap: DimensionPx.Layout.SPACING_3x,
        justifyContent: "start",
        width: "100%",
      }}
    >
      {props.icon && (
        <div
          style={{
            alignItems: "center",
            aspectRatio: "1/1",
            backgroundColor: ColorCSS[Color.MAIN],
            borderRadius: "100%",
            color: ColorCSS[Color.BACKGROUND],
            display: "flex",
            gap: DimensionPx.Layout.SPACING_2x,
            justifyContent: "center",
            width: DimensionPx.Title.HEIGHT,
          }}
        >
          {props.icon}
        </div>
      )}
      <div
        style={{
          alignItems: "start",
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          gap: DimensionPx.Layout.SPACING_1x,
          justifyContent: "center",
        }}
      >
        <div
          style={{
            alignItems: "center",
            fontSize: FontSizeEm.LARGE,
            fontWeight: "bold",
            height: DimensionPx.Title.HEIGHT,
            display: "flex",
          }}
        >
          {props.title}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: DimensionPx.Layout.SPACING_2x,
            width: "100%",
          }}
        >
          {props.children}
        </div>
      </div>
    </div>
  );
}

export function SectionLine({
  style,
  tag,
  children,
  ...divProps
}: React.HTMLAttributes<HTMLDivElement> & { tag: string }) {
  const sharedStyle: React.CSSProperties = {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    gap: DimensionPx.Layout.SPACING_1x,
  };
  return (
    <div {...divProps} style={mergeStyles(style, sharedStyle)}>
      <span style={{ fontStyle: "normal", fontWeight: "bold" }}>{tag}</span>
      <span style={sharedStyle}>{children}</span>
    </div>
  );
}
