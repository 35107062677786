import React from "react";
import { TbRefresh } from "react-icons/tb";
import {
  AppError,
  AppText,
  Color,
  ColorCSS,
  DimensionPx,
  Page,
} from "../utils/constants";
import { MOCK_VALID_OTP } from "../utils/mocks";
import { asCssTransition, mergeStyles } from "../utils/react";
import { mask } from "../utils/string";
import { AuthForm } from "./AuthForm";
import { OtpField, msToSecs, useCanSendNewOTP } from "./OtpField";
import { PageButton, PageButtonTheme } from "./PageButton";
import { Spacer } from "./Spacer";
import { Title } from "./Title";
import { useAccount } from "./hooks/useAccount";
import {
  useAccountSetter,
  useAppErrorSetter,
  useAppPageSetter,
  useAppState,
} from "./hooks/useAppDuck";
import { useAuthFlow } from "./hooks/useAuthFlow";

interface AccountProfileOtpConfirmProps
  extends React.HTMLProps<HTMLDivElement> {}

export function AccountProfileOtpConfirm({
  style,
  ...divProps
}: AccountProfileOtpConfirmProps) {
  const { page } = useAppState();
  const setAppPage = useAppPageSetter();
  const wrapperStyle = mergeStyles(
    style,
    AccountProfileOtpConfirm.wrapperStyle
  );
  const [account] = useAccount();
  const setAccount = useAccountSetter();
  const isEmailChange = !!account?.pendingEmail;
  const [requestOtpDate, setRequestOtpDate] = React.useState<Date>();
  const [otpValue, setOtpValue] = React.useState("");
  const [isValidOTP, setIsValidOtp] = React.useState(false);
  const validOtpLength = MOCK_VALID_OTP.length;
  const [sendNewOtp, timeToNextOtp] = useCanSendNewOTP(requestOtpDate);
  const setAppError = useAppErrorSetter();

  const {
    otp: { request: requestOTP, validate: validateOTP },
  } = useAuthFlow();

  const onOtpValueChange = React.useCallback(
    (otpValue: string) => {
      const isValid = otpValue.length === validOtpLength;
      setIsValidOtp(isValid);
      setOtpValue(otpValue);
    },
    [validOtpLength]
  );

  const handleSubmitOtp = React.useCallback(async () => {
    if (!isValidOTP) return;
    try {
      await validateOTP(otpValue);
      setAccount({
        account: {
          email: isEmailChange ? account?.pendingEmail : account?.email,
          phone: !isEmailChange ? account?.pendingPhone : account?.phone,
          pendingEmail: "",
          pendingPhone: "",
        },
      });
      setAppPage(Page.ACCOUNT_PERSONAL_DETAILS);
    } catch (e) {
      setAppError(AppError.INVALID_OTP);
    }
  }, [
    account?.email,
    account?.pendingEmail,
    account?.pendingPhone,
    account?.phone,
    isEmailChange,
    isValidOTP,
    otpValue,
    setAccount,
    setAppError,
    setAppPage,
    validateOTP,
  ]);

  const handleRequestOTP = React.useCallback(() => {
    if (!sendNewOtp) return;
    requestOTP(isEmailChange);
    setRequestOtpDate(new Date());
  }, [isEmailChange, requestOTP, sendNewOtp]);

  // request OTP the first time this is launched
  React.useEffect(() => {
    if (page === Page.ACCOUNT_PROFILE_OTP_CONFIRM) {
      handleRequestOTP();
    }
  }, [handleRequestOTP, page]);

  return (
    <div style={wrapperStyle} {...divProps}>
      <Title>{AppText.ACCOUNT_OTP_FORM}</Title>
      <div style={{ textAlign: "center" }}>
        {isEmailChange
          ? AppText.ENTER_CODE_SENT_EMAIL
          : AppText.ENTER_CODE_SENT_PHONE}
        {": "}
        {mask(isEmailChange ? account?.pendingEmail : account?.pendingPhone)}
      </div>
      <OtpField
        onChange={onOtpValueChange}
        length={validOtpLength}
        onReturn={handleSubmitOtp}
      />
      <Spacer height={0} />
      <div
        onClick={() => handleRequestOTP()}
        style={{
          alignItems: "center",
          color: ColorCSS[sendNewOtp ? Color.APPROVE : Color.BACKGROUND],
          display: "flex",
          fontWeight: "bold",
          gap: DimensionPx.Layout.SPACING_1x,
          justifyContent: "center",
        }}
      >
        <TbRefresh
          size={DimensionPx.Button.ICON}
          style={{
            transform: `rotate(${sendNewOtp ? 15 : 105}deg)`,
            transition: asCssTransition`transform`,
          }}
        />
        <span
          style={{
            overflow: "hidden",
            textAlign: "left",
            textOverflow: "clip",
            textWrap: "nowrap",
            transition: asCssTransition`width`,
            width: `${sendNewOtp ? 8 : 12}em`,
          }}
        >
          {sendNewOtp
            ? AppText.RESEND_OTP
            : `${AppText.RESEND_OTP_WAIT} ${msToSecs(timeToNextOtp)}`}
        </span>
      </div>
      <PageButton
        onClick={() => handleSubmitOtp()}
        style={{
          ...PAGE_BUTTON_STYLES,
          pointerEvents: isValidOTP ? "inherit" : "none",
        }}
        theme={isValidOTP ? PageButtonTheme.APPROVE : PageButtonTheme.MUTED}
      >
        {AppText.CONFIRM}
      </PageButton>
      <PageButton
        // TODO: submit only valid values to next page
        onClick={() => setAppPage(Page.ACCOUNT_PROFILE_EDIT_CONTACT)}
        style={PAGE_BUTTON_STYLES}
      >
        {AppText.GO_BACK}
      </PageButton>
      <Spacer height={0} />
    </div>
  );
}

const PAGE_BUTTON_STYLES: React.CSSProperties = { width: "100%" };

AccountProfileOtpConfirm.pages = new Set([Page.ACCOUNT_PROFILE_OTP_CONFIRM]);
AccountProfileOtpConfirm.shouldRenderPage = undefined;
AccountProfileOtpConfirm.wrapperStyle = {
  display: "flex",
  width: AuthForm.wrapperStyle.width,
} as React.CSSProperties;
