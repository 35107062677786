import React from "react";
import { Page } from "../utils/constants";
import { mergeStyles } from "../utils/react";
import { PageFlow } from "./PageFlow";
import PageLogo from "./PageLogo";
import { PageModal } from "./PageModal";
import { useAccount } from "./hooks/useAccount";
import { useAppPageSetter, useAppState } from "./hooks/useAppDuck";

interface PagesProps extends React.HTMLProps<HTMLDivElement> {}

export default function Pages(props: PagesProps) {
  const [account] = useAccount();
  const { page } = useAppState();
  const setPage = useAppPageSetter();

  React.useEffect(
    function resetUnAuthPage() {
      if (!page || (Pages.requireAuth.has(page) && !account?.isAuthenticated)) {
        return setPage(Page.DEFAULT);
      }
      if (page === Page.UN_AUTH && account?.isAuthenticated) {
        return setPage(Page.ACCOUNT_PROFILE);
      }
    },
    [account?.isAuthenticated, page, setPage]
  );

  const { style, ...divProps } = props;

  return (
    <div style={mergeStyles(Pages.wrapperStyle, style)} {...divProps}>
      <PageFlow />
      <PageLogo page={page} />
      <PageModal />
    </div>
  );
}

Pages.wrapperStyle = {
  display: "flex",
  height: "100vh",
  justifyContent: "center",
  overflow: "hidden",
  overscrollBehavior: "none",
  position: "relative",
} as React.CSSProperties;
// TODO: allow padding accept type Record<"default" | "top" | "left" | "bottom" | "right", string | number>

/** Page that require authentication to access */
Pages.requireAuth = Object.freeze(
  new Set([
    Page.ACCOUNT_PERSONAL_DETAILS,
    Page.ACCOUNT_PERSONAL_DETAILS_EDIT,
    Page.ACCOUNT_PROFILE,
    Page.ACCOUNT_PROFILE_EDIT_CONTACT,
    Page.ACCOUNT_PROFILE_OTP_CONFIRM,
    Page.PRODUCT_PACKAGES,
  ])
);
