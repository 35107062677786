export function prefixWith(v: unknown, prefix: string) {
  const valueLength = `${v}`.length;
  return `${prefix.substring(0, Math.max(0, prefix.length - valueLength))}${v}`;
}

export function suffixWith(v: unknown, suffix: string) {
  const valueLength = `${v}`.length;
  return `${v}${suffix.substring(valueLength)}`;
}

export function mask(v: unknown, head: number = 2, tail: number = 4) {
  const strV = `${v}`;
  const middleLen = Math.max(0, strV.length - (head + tail));
  return [
    strV.substring(0, head),
    "*".repeat(middleLen),
    strV.substring(strV.length - tail),
  ]
    .filter(Boolean)
    .join("");
}
