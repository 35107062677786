import React from "react";
import { TbRefresh } from "react-icons/tb";
import {
  AppError,
  AppText,
  Color,
  ColorCSS,
  DimensionPx,
  Page,
} from "../utils/constants";
import { MOCK_VALID_OTP } from "../utils/mocks";
import { asCssTransition, mergeStyles } from "../utils/react";
import { mask } from "../utils/string";
import { AuthForm } from "./AuthForm";
import { OtpField, msToSecs, useCanSendNewOTP } from "./OtpField";
import { PageButton, PageButtonTheme } from "./PageButton";
import { Spacer } from "./Spacer";
import { Title } from "./Title";
import { useAccount } from "./hooks/useAccount";
import {
  useAppErrorSetter,
  useAppPageSetter,
  useAppState,
} from "./hooks/useAppDuck";
import { useAuthFlow } from "./hooks/useAuthFlow";

interface AuthCodeProps extends React.HTMLProps<HTMLDivElement> {}

export function AuthCode({ style, ...divProps }: AuthCodeProps) {
  const { page } = useAppState();
  const setAppPage = useAppPageSetter();
  const wrapperStyle = mergeStyles(style, AuthCode.wrapperStyle);
  const [account] = useAccount();
  const [useEmail, setUseEmail] = React.useState<boolean>();
  const canUseEitherEmailOrPhone = Boolean(account?.email && account?.phone);
  const [requestOtpDate, setRequestOtpDate] = React.useState<Date>();
  const [otpValue, setOtpValue] = React.useState("");
  const [isValidOTP, setIsValidOtp] = React.useState(false);
  const validOtpLength = MOCK_VALID_OTP.length;
  const [sendNewOtp, timeToNextOtp] = useCanSendNewOTP(requestOtpDate);
  const setAppError = useAppErrorSetter();

  const {
    otp: { request: requestOTP, validate: validateOTP },
  } = useAuthFlow();

  const onOtpValueChange = React.useCallback(
    (otpValue: string) => {
      const isValid = otpValue.length === validOtpLength;
      setIsValidOtp(isValid);
      setOtpValue(otpValue);
    },
    [validOtpLength]
  );

  const handleSubmitOtp = React.useCallback(async () => {
    if (!isValidOTP) return;
    try {
      await validateOTP(otpValue);
      setAppPage(Page.ACCOUNT_PROFILE);
    } catch (e) {
      setAppError(AppError.INVALID_OTP);
    }
  }, [isValidOTP, otpValue, setAppError, setAppPage, validateOTP]);

  const handleRequestNewOTP = React.useCallback(
    (shouldUseEmail: boolean) => {
      requestOTP(shouldUseEmail);
      setRequestOtpDate(new Date());
      setUseEmail(shouldUseEmail);
    },
    [requestOTP]
  );

  const handleRequestOTP = React.useCallback(
    (shouldUseEmail: boolean) => {
      if (!sendNewOtp) return;
      handleRequestNewOTP(shouldUseEmail);
    },
    [handleRequestNewOTP, sendNewOtp]
  );

  // request OTP the first time this is launched
  React.useEffect(() => {
    setUseEmail((prevUseEmail) => {
      const newUseEmail = !!account?.email;
      if (page === Page.AUTH_CODE && prevUseEmail === undefined) {
        handleRequestOTP(newUseEmail);
        return newUseEmail;
      }
      return prevUseEmail;
    });
  }, [account?.email, handleRequestOTP, page]);

  return (
    <div style={wrapperStyle} {...divProps}>
      <Title>{AppText.ACCOUNT_OTP_FORM}</Title>
      <div style={{ textAlign: "center" }}>
        {useEmail
          ? AppText.ENTER_CODE_SENT_EMAIL
          : AppText.ENTER_CODE_SENT_PHONE}
        {": "}
        {mask(useEmail ? account?.email : account?.phone)}
      </div>
      <OtpField
        onChange={onOtpValueChange}
        length={validOtpLength}
        onReturn={handleSubmitOtp}
      />
      <Spacer height={0} />
      <div
        onClick={() => handleRequestOTP(!!useEmail)}
        style={{
          alignItems: "center",
          color: ColorCSS[sendNewOtp ? Color.APPROVE : Color.BACKGROUND],
          display: "flex",
          fontWeight: "bold",
          gap: DimensionPx.Layout.SPACING_1x,
          justifyContent: "center",
        }}
      >
        <TbRefresh
          size={DimensionPx.Button.ICON}
          style={{
            transform: `rotate(${sendNewOtp ? 15 : 105}deg)`,
            transition: asCssTransition`transform`,
          }}
        />
        <span
          style={{
            overflow: "hidden",
            textAlign: "left",
            textOverflow: "clip",
            textWrap: "nowrap",
            transition: asCssTransition`width`,
            width: `${sendNewOtp ? 8 : 12}em`,
          }}
        >
          {sendNewOtp
            ? AppText.RESEND_OTP
            : `${AppText.RESEND_OTP_WAIT} ${msToSecs(timeToNextOtp)}`}
        </span>
      </div>
      <Spacer height={0} />
      <div
        onClick={() => handleRequestNewOTP(!useEmail)}
        style={{
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          gap: DimensionPx.Layout.SPACING_2x,
          justifyContent: "center",
          opacity: canUseEitherEmailOrPhone ? 1 : 0,
          pointerEvents: canUseEitherEmailOrPhone ? "inherit" : "none",
          transition: asCssTransition`opacity`,
        }}
      >
        <span>{AppText.NO_CODE_SENT}</span>
        <span style={{ color: ColorCSS[Color.APPROVE], fontWeight: "bolder" }}>
          {useEmail ? AppText.SEND_OTP_PHONE : AppText.SEND_OTP_EMAIL}
        </span>
      </div>
      <Spacer height={0} />
      <PageButton
        onClick={() => handleSubmitOtp()}
        style={{
          ...PAGE_BUTTON_STYLES,
          pointerEvents: isValidOTP ? "inherit" : "none",
        }}
        theme={isValidOTP ? PageButtonTheme.APPROVE : PageButtonTheme.MUTED}
      >
        {AppText.CONFIRM}
      </PageButton>
      <PageButton
        // TODO: submit only valid values to next page
        onClick={() => setAppPage(Page.AUTH_FORM)}
        style={PAGE_BUTTON_STYLES}
      >
        {AppText.GO_BACK}
      </PageButton>
    </div>
  );
}

const PAGE_BUTTON_STYLES: React.CSSProperties = { width: "100%" };

AuthCode.pages = new Set([Page.AUTH_CODE]);
AuthCode.shouldRenderPage = undefined;
AuthCode.wrapperStyle = {
  display: "flex",
  width: AuthForm.wrapperStyle.width,
} as React.CSSProperties;
