import React from "react";
import { IoIosArrowForward } from "react-icons/io";
import { AppText, DimensionPx, Page } from "../utils/constants";
import { formatDate } from "../utils/product";
import { mergeStyles } from "../utils/react";
import { SectionLine } from "./AccountProfileSection";
import { Spacer } from "./Spacer";
import { useAccount } from "./hooks/useAccount";
import { useAppPageSetter } from "./hooks/useAppDuck";

interface AccountPersonalDetailsProps extends React.HTMLProps<HTMLDivElement> {}

export function AccountPersonalDetails({
  style,
  ...divProps
}: AccountPersonalDetailsProps) {
  const [account, updateAccount] = useAccount();
  const setPage = useAppPageSetter();

  const wrapperStyle = mergeStyles(style, AccountPersonalDetails.wrapperStyle);

  return (
    <div style={wrapperStyle} {...divProps}>
      <div
        style={{
          backgroundColor: "white",
          display: "flex",
          flexDirection: "column",
          gap: DimensionPx.Layout.SPACING_3x,
          overflow: "scroll",
          padding: DimensionPx.Layout.SPACING_2x,
          get width() {
            return `calc(100% - ${this.padding * 2}px)`;
          },
        }}
      >
        <Spacer height={0} />
        <SectionLine
          onClick={() => setPage(Page.ACCOUNT_PERSONAL_DETAILS_EDIT)}
          style={{ fontStyle: account?.name ? "initial" : "italic" }}
          tag={AppText.PROFILE_FULLNAME}
        >
          {account?.name ?? AppText.ADD_PROFILE_NAME}
          <IoIosArrowForward />
        </SectionLine>
        <SectionLine
          onClick={() => {
            updateAccount({
              pendingEmail: "",
              pendingPhone: account?.phone ?? undefined,
            });
            setPage(Page.ACCOUNT_PROFILE_EDIT_CONTACT);
          }}
          style={{ fontStyle: account?.phone ? "initial" : "italic" }}
          tag={AppText.PROFILE_PHONE}
        >
          {account?.phone ?? AppText.ADD_PROFILE_PHONE}
          <IoIosArrowForward />
        </SectionLine>
        <SectionLine
          onClick={() => {
            updateAccount({
              pendingEmail: account?.email ?? undefined,
              pendingPhone: "",
            });
            setPage(Page.ACCOUNT_PROFILE_EDIT_CONTACT);
          }}
          style={{ fontStyle: account?.email ? "initial" : "italic" }}
          tag={AppText.PROFILE_EMAIL}
        >
          {account?.email ?? AppText.ADD_PROFILE_EMAIL}
          <IoIosArrowForward />
        </SectionLine>
        <SectionLine
          onClick={() => setPage(Page.ACCOUNT_PERSONAL_DETAILS_EDIT)}
          style={{ fontStyle: account?.date?.birth ? "initial" : "italic" }}
          tag={AppText.PROFILE_DOB}
        >
          {account?.date?.birth
            ? formatDate(account.date.birth)
            : AppText.ADD_PROFILE_DOB}
          <IoIosArrowForward />
        </SectionLine>
        <Spacer height={0} />
      </div>
    </div>
  );
}

/** Pages that this component is displayed for */
AccountPersonalDetails.pages = Object.freeze(
  new Set([Page.ACCOUNT_PERSONAL_DETAILS])
);
AccountPersonalDetails.shouldRenderPage = undefined;
AccountPersonalDetails.wrapperStyle = {
  alignItems: "start",
  display: "flex",
  top: 0,
  width: `100vw`,
} as React.CSSProperties;
