import { merge } from "lodash";
import { createDuck } from "react-ducks";
import { urlEncode } from "react-state-url-fragment";
import { AppState, Application, Modal, Page } from "../../utils/constants";

const DUCK_NAME = "AppDuck";

export enum ActionType {
  SET_ACCOUNT = "setAccount",
  SET_ERROR = "setError",
  SET_MODAL = "setModal",
  SET_PAGE = "setPage",
  SET_PRODUCT_ID = "setProductId",
}

export interface Action<
  // optional
  Type extends ActionType = ActionType,
  Payload = unknown
> {
  type: Type;
  payload?: Payload;
}

/** App duck reducers with action and payload types */
export type Reducers<T extends ActionType> = {
  [k in T]: (
    state: AppState,
    action: Action<
      k,
      {
        [ActionType.SET_ACCOUNT]: Pick<AppState, "account" | "api">;
        [ActionType.SET_ERROR]: string;
        [ActionType.SET_MODAL]: Modal | null;
        [ActionType.SET_PAGE]: Page;
        [ActionType.SET_PRODUCT_ID]: string | null;
      }[k]
    >
  ) => AppState;
};
export type Reducer<T extends ActionType> = Reducers<T>[T];
export type Payload<T extends ActionType> = Parameters<
  Reducer<T>
>[1]["payload"];

export default createDuck<typeof DUCK_NAME, AppState, ActionType>({
  name: DUCK_NAME,
  initialState: Application.BASE_STATE,
  reducers: {
    [ActionType.SET_ACCOUNT]: completeReducer((_, action) => ({
      ...action.payload,
    })),
    [ActionType.SET_ERROR]: completeReducer((_, action) => ({
      error: action.payload,
      modal: Modal.ERROR,
    })),
    [ActionType.SET_PAGE]: completeReducer((_, action) => ({
      page: action.payload,
    })),
    [ActionType.SET_PRODUCT_ID]: completeReducer((_, action) => ({
      product: {
        details: {
          id: action.payload ?? null,
        },
      },
    })),
    [ActionType.SET_MODAL]: completeReducer((_, action) => ({
      modal: action.payload,
    })),
  } as Reducers<ActionType>,
});

/**
 * Ensure the result of the reducer is correctly merged into the existing state
 */
function completeReducer<S, T>(partialReducer: (_: S, __: T) => Partial<S>) {
  return (state: S, action: T) => {
    const completeState = merge({}, state, partialReducer(state, action));
    window.location.hash = urlEncode(completeState);
    return completeState;
  };
}
