import { GoogleOAuthProvider } from "@react-oauth/google";
import { QueryClientProvider } from "@tanstack/react-query";
import React from "react";
import { Provider } from "react-ducks";
import { GoogleApp } from "../utils/constants";
import queryClient from "../utils/queryClient";
import "./App.css";
import ErrorResetBoundary from "./ErrorResetBoundary";
import Pages from "./Pages";
import Spinner from "./Spinner";
import { context } from "./ducks";
import {
  OfflineStorageContext,
  useOfflineStorageContextValue,
} from "./hooks/useOfflineStorage";

function App() {
  const offlineContext = useOfflineStorageContextValue();

  return (
    <React.StrictMode>
      <OfflineStorageContext.Provider value={offlineContext}>
        <Provider Context={context}>
          <GoogleOAuthProvider clientId={GoogleApp.CLIENT_ID}>
            <QueryClientProvider client={queryClient}>
              <ErrorResetBoundary>
                <React.Suspense fallback={<Spinner />}>
                  <Pages className={cls`pages`} />
                </React.Suspense>
              </ErrorResetBoundary>
            </QueryClientProvider>
          </GoogleOAuthProvider>
        </Provider>
      </OfflineStorageContext.Provider>
    </React.StrictMode>
  );
}

function cls(s?: string | TemplateStringsArray) {
  return [`App`, s?.toString()].filter(Boolean).join("-");
}

export default App;
