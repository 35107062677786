import React from "react";
import { DimensionPx, Page, PageLayer } from "../utils/constants";
import Logo from "./Logo";
import { useWindowSize } from "./hooks/useWindowSize";

export default function PageLogo({ page = Page.DEFAULT }: { page?: Page }) {
  const { height, width } = useWindowSize();

  const { size, coords } = React.useMemo(() => {
    const coordsFn =
      PageLogo.positionPx[page] ?? PageLogo.positionPx[Page.DEFAULT];
    const sizeFn = PageLogo.sizePx[page] ?? PageLogo.sizePx[Page.DEFAULT];
    return {
      coords: coordsFn({ width, height }),
      size: sizeFn(),
    };
  }, [page, width, height]);

  return (
    <Logo
      data-testid="logo"
      size={size}
      style={{
        ...coords,
        position: "fixed",
        zIndex: PageLayer.TWO,
      }}
      tabIndex={-1}
    />
  );
}

/** The sizes the page logo can take */
PageLogo.sizePx = {} as Record<Page, () => number>;
Object.assign(PageLogo.sizePx, {
  [Page.UN_AUTH]: () => DimensionPx.Title.HEIGHT * 4,
  ...Object.fromEntries(
    /** Sizes that use the same setting as UN_AUTH */
    Object.freeze([
      Page.ACCOUNT_PERSONAL_DETAILS,
      Page.ACCOUNT_PERSONAL_DETAILS_EDIT,
      Page.ACCOUNT_PROFILE_EDIT_CONTACT,
      Page.ACCOUNT_PROFILE_OTP_CONFIRM,
    ]).map((k) => [k, PageLogo.sizePx[Page.UN_AUTH]])
  ),
  [Page.AUTH_FORM]: () => DimensionPx.Title.HEIGHT * 3,
  ...Object.fromEntries(
    /** Sizes that use the same setting as AUTH_FORM */
    Object.freeze([Page.AUTH_CODE]).map((k) => [
      k,
      () => PageLogo.sizePx[Page.AUTH_FORM](),
    ])
  ),
  [Page.ACCOUNT_PROFILE]: () =>
    DimensionPx.Title.HEIGHT -
    PageLogo.positionPx[Page.ACCOUNT_PROFILE]().top * 2,
  ...Object.fromEntries(
    /** Sizes that use the same setting as ACCOUNT_PROFILE */
    Object.freeze([
      Page.PRODUCT_PACKAGES,
      Page.PRODUCT_SCAN,
      Page.PRODUCT_SEARCH,
    ]).map((k) => [k, () => PageLogo.sizePx[Page.ACCOUNT_PROFILE]()])
  ),
} as typeof PageLogo.sizePx);

/** The cartesian coordinates the page logo can be positioned at */
PageLogo.positionPx = {} as Record<
  Page,
  (
    screen?: { width: number; height: number },
    /** Passed to control the logo size calculated for */
    size?: number
  ) => { top: number; left: number }
>;
Object.assign(PageLogo.positionPx, {
  [Page.UN_AUTH]: (screen, size = PageLogo.sizePx[Page.UN_AUTH]()) => {
    return {
      top: ((screen?.height ?? 0) - size) / 9,
      left: ((screen?.width ?? 0) - size) / 2,
    };
  },
  ...Object.fromEntries(
    /** Sizes that use the same setting as UN_AUTH */
    Object.freeze([
      Page.ACCOUNT_PERSONAL_DETAILS,
      Page.ACCOUNT_PERSONAL_DETAILS_EDIT,
      Page.ACCOUNT_PROFILE_EDIT_CONTACT,
      Page.ACCOUNT_PROFILE_OTP_CONFIRM,
    ]).map((k) => [k, PageLogo.positionPx[Page.UN_AUTH]])
  ),
  [Page.AUTH_FORM]: (screen) => {
    const size = PageLogo.sizePx[Page.AUTH_FORM]();
    const pos = PageLogo.positionPx[Page.DEFAULT](screen, size);
    return {
      ...pos,
      top: pos.top / 2,
    };
  },
  ...Object.fromEntries(
    /** Coordinates that use the same setting as AUTH_FORM */
    Object.freeze([Page.AUTH_CODE]).map((k) => [
      k,
      (screen, size) => PageLogo.positionPx[Page.AUTH_FORM](screen, size),
    ])
  ),
  [Page.ACCOUNT_PROFILE]: (_) => {
    return {
      left: DimensionPx.Layout.SPACING_1x * 2,
      top: DimensionPx.Layout.SPACING_1x * 2,
    };
  },
  ...Object.fromEntries(
    /** Coordinates that use the same setting as ACCOUNT_PROFILE */
    Object.freeze([
      Page.PRODUCT_PACKAGES,
      Page.PRODUCT_SCAN,
      Page.PRODUCT_SEARCH,
    ]).map((k) => [
      k,
      (screen, size) => PageLogo.positionPx[Page.ACCOUNT_PROFILE](screen, size),
    ])
  ),
} as typeof PageLogo.positionPx);
