import { merge } from "lodash";
import { urlDecode } from "react-state-url-fragment";

export enum Page {
  /** Personal details */
  ACCOUNT_PERSONAL_DETAILS = "accountPersonalDetails",
  /** Form for changing user profile details i.e. name and dob */
  ACCOUNT_PERSONAL_DETAILS_EDIT = "accountPersonalDetailsEdit",
  /** User profile page */
  ACCOUNT_PROFILE = "accountProfile",
  /** Form for changing user profile contact */
  ACCOUNT_PROFILE_EDIT_CONTACT = "accountProfileContactEdit",
  /** Form for confirming phone or email change */
  ACCOUNT_PROFILE_OTP_CONFIRM = "accountProfileConfirmOtp",
  /** Submitting auth codes */
  AUTH_CODE = "authCode",
  /** Submitting auth idenitification */
  AUTH_FORM = "authForm",
  /** Viewing owned product packages */
  PRODUCT_PACKAGES = "productPackages",
  /** Scanning product codes */
  PRODUCT_SCAN = "productScan",
  /** Searching through products on ensured */
  PRODUCT_SEARCH = "productSearch",
  /** Home page when user is not auth'd */
  UN_AUTH = "unAuth",
  /** Default page in any other situation */
  DEFAULT = Page.UN_AUTH,
}

export enum Pattern {
  EMAIL = "[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$",
  PHONE = "\\d{6,11}",
}

export enum Modal {
  ERROR,
  PRIVACY_POLICY,
  PRODUCT_CHILD_ITEMS,
  PRODUCT_SEARCH_FILTER,
  PRODUCT_SEARCH_SORT,
  TERMS_CONDITIONS,
}

type Nullable<T> = {
  [P in keyof T]?: T[P] | null;
};

type LinkedAccount = Pick<UserDetails, "id" | "name">;
export interface Account
  extends Pick<
    Nullable<UserDetails>,
    keyof LinkedAccount | "date" | "email" | "phone" | "images" | "setting"
  > {
  isAuthenticated?: boolean;
  pendingEmail?: string;
  pendingPhone?: string;
}

export interface UserDetails {
  date: {
    birth?: Date;
  };
  email: string;
  id: string;
  images: {
    profile?: string;
  };
  name: string;
  phone?: string;
  setting: {
    notice: {
      purchase: {
        request: boolean;
        confirmation: boolean;
      };
    };
  };
}

export enum AuditStatus {
  APPROVED = "approved",
  FLAGGED = "flagged",
}

export type LinkedProduct = Pick<
  ProductDetails,
  "account" | "history" | "id" | "name" | "photos"
>;

export interface ProductDetails {
  account: {
    manufacturer: string;
  };
  active: boolean; // false by default
  children: ReadonlyArray<LinkedProduct>;
  /** The most recent audit is always at index `0` */
  audits: ReadonlyArray<{
    date: Date;
    notes: string;
    status: AuditStatus;
    verifier: string; // account
  }>;
  description: string;
  /** The most recent ownership is always at index `0` */
  history: ReadonlyArray<{
    currency: {
      fare: number;
      type: Currency;
    };
    date?: {
      acquired: Date;
    };
    owner?: string;
  }>;
  id: string | null;
  name: string;
  parents: ReadonlyArray<LinkedProduct>;
  photos: {
    featured?: number;
    urls: ReadonlyArray<string>;
  };
  purchaseRequest?: LinkedAccount & { date: Date };
  tags: ReadonlyArray<{ name: string; slug: string }>;
}

export interface AppState {
  account?: Account;
  api?: {
    key: string | null;
  };
  error?: string;
  modal?: Modal | null;
  page?: Page;
  product?: {
    details?: Partial<Pick<ProductDetails, "id">>;
  };
}

/** Predefined style colors see index.css */
export enum Color {
  ALERT = "ALERT",
  APPROVE = "APPROVE",
  BACKGROUND = "BACKGROUND",
  FOREGROUND = "FOREGROUND",
  HIGHLIGHT = "HIGHLIGHT",
  MAIN = "MAIN",
  TRANSPARENT = "TRANSPARENT",
}
const cssColorVar = (name: string | TemplateStringsArray) =>
  `var(--color-${name})` as const;
export const ColorCSS = {
  [Color.ALERT]: cssColorVar`al`,
  [Color.APPROVE]: cssColorVar`ap`,
  [Color.BACKGROUND]: cssColorVar`bg`,
  [Color.FOREGROUND]: cssColorVar`fg`,
  [Color.HIGHLIGHT]: cssColorVar`hl`,
  [Color.MAIN]: cssColorVar`fr`,
  [Color.TRANSPARENT]: Color.TRANSPARENT.toLowerCase(),
} as const;

export const Timing = {
  Millis: { ONE_SEC: 1000 },
  Secs: {
    ONE_MIN: 60,
    Transition: {
      DELAY: 0,
      DURATION: 0.3,
    },
  },
};

export const DimensionPx = {
  App: {
    MIN_HY: 768,
    MIN_WX: 432,
  },
  Button: {
    HEIGHT: 44,
    get PADDING() {
      return DimensionPx.Layout.SPACING_2x;
    },
    get ICON() {
      return this.HEIGHT - this.PADDING * 2;
    },
  },
  Input: {
    HEIGHT: 44,
    get PADDING() {
      return DimensionPx.Layout.SPACING_3x;
    },
  },
  ProductItem: {
    HEIGHT: 72,
  },
  Layout: {
    SPACING_0x: 2,
    SPACING_1x: 4,
    SPACING_2x: 12,
    SPACING_3x: 24,
  },
  Title: {
    get HEIGHT() {
      return DimensionPx.Button.HEIGHT;
    },
  },
} as const;

export enum FontFamily {
  DEFAULT = "default-regular",
}

export enum FontSizeEm {
  XSMALL = "0.6em",
  SMALL = "0.8em",
  REGULAR = "1.0em",
  LARGE = "1.2em",
  XLARGE = "1.6em",
}

enum AllyRole {
  BUTTON = "button",
  LINK = "link",
}
export const Ally = {
  Role: AllyRole,
} as const;

/** Accessibility enabled text */
export enum AppText {
  ADD_ITEMS = "Add Items",
  ADD_PROFILE_DOB = "add date of birth",
  ADD_PROFILE_EMAIL = "add email address",
  ADD_PROFILE_PHONE = "add phone number",
  ADD_PROFILE_NAME = "add full name",
  AND = "and",
  APPLICATION = "Ensured.ng",
  ACCOUNT_AUTH_FORM = "Enter Your Details",
  ACCOUNT_OTP_FORM = "Enter One Time Password",
  COMPANY = "Proverka",
  CONFIRM = "Confirm",
  CONTINUE = "Continue",
  CHANGE_EMAIL = "Change Email Address",
  CHANGE_PHONE = "Change Phone Number",
  CHANGE_EMAIL_INSTRUCTIONS = "Please provide the new email you want to use. It must be different from your previous address",
  CHANGE_PHONE_INSTRUCTIONS = "Please provide the new phone you want to use. It must be different from your previous number",
  DATE_OF_BIRTH = "Date of birth",
  EDIT_PROFILE = "Edit Profile",
  ENTER_VALID = "Please enter valid",
  ENTER_CODE_SENT_EMAIL = "Enter code sent to the email you provided",
  ENTER_CODE_SENT_PHONE = "Enter code sent to the phone you provided",
  EXPAND_PRODUCT_HISTORY = "view ownership history",
  FACEBOOK = "Facebook",
  FIRST_NAME = "First name",
  GO_BACK = "Back",
  GOOGLE = "Google",
  LAST_NAME = "Last name",
  LOGOUT = "Logout",
  NO_ACCOUNT_NEEDED = "I only need to verify a product",
  NO_CAMERAS = "No cameras detected",
  NO_CODE_SENT = "Did not get a code?",
  NOTIFICATIONS = "Notifications",
  PAYMENT_METHOD = "Payment method",
  PERSONAL_DETAILS = "Personal details",
  PLEASE_USE_ACCOUNT = "Please use your account to make purchases",
  PRIVACY_POLICY = "Privacy Policy",
  PRODUCT_FIND = "View Items",
  PRODUCT_PKGS = "My Items",
  PRODUCT_QR_VALID = "Product detected",
  PRODUCT_SCAN = "Scan Product",
  PROFILE_DOB = "Date of birth",
  PROFILE_EMAIL = "Email address",
  PROFILE_EDIT = "Edit Profile",
  PROFILE_FULLNAME = "Full Name",
  PROFILE_PAGE = "My Profile",
  PROFILE_PHONE = "Phone number",
  PURCHASE_CANCEL = "Cancel Purchase",
  PURCHASE_REQUEST = "Request Purchase",
  PWA_INSTALL_CUSTOM_IOS = "To install this app, tap 'Share' and then 'Add to Home Screen'",
  RESEND_OTP = "Resend OTP",
  RESEND_OTP_WAIT = "Resend OTP in",
  SALE_APPROVE = "Approve Purchase",
  SALE_DISABLE = "Disable Sale",
  SALE_DISABLED = "Not ready for sale",
  SALE_ENABLE = "Enable Sale",
  SALUT = "Hi, ",
  SAVE = "Save",
  SEND_OTP_EMAIL = "Request on email",
  SEND_OTP_PHONE = "Request on phone",
  SETTINGS = "Settings",
  SHOW_BUNDLES = "Show Bundles",
  TERMS_CONDITIONS = "Terms and Conditions",
  TRY_AGAIN = "Try Again",
  // combining existing keys
  WELCOME = `Welcome to ${AppText.APPLICATION}`,
  ACCEPT_ACCOUNT = `By using ${AppText.APPLICATION} you agree to our`,
  CONTINUE_WITH = `${AppText.CONTINUE} with`,
  AUTH_BTN = `Use Email or Phone`,
  AUTH_BTN_FACEBOOK = `${AppText.CONTINUE_WITH} ${AppText.FACEBOOK}`,
  AUTH_BTN_GOOGLE = `${AppText.CONTINUE_WITH} ${AppText.GOOGLE}`,
}

export const PageTitle = {
  [Page.ACCOUNT_PERSONAL_DETAILS]: AppText.PERSONAL_DETAILS,
  [Page.ACCOUNT_PERSONAL_DETAILS_EDIT]: AppText.PROFILE_EDIT,
  [Page.ACCOUNT_PROFILE]: AppText.PROFILE_PAGE,
  [Page.ACCOUNT_PROFILE_EDIT_CONTACT]: AppText.PROFILE_EDIT,
  [Page.ACCOUNT_PROFILE_OTP_CONFIRM]: AppText.PROFILE_EDIT,
  [Page.PRODUCT_SCAN]: AppText.PRODUCT_SCAN,
  [Page.PRODUCT_SEARCH]: AppText.PRODUCT_FIND,
  [Page.PRODUCT_PACKAGES]: AppText.PRODUCT_PKGS,
} as const;

export const FacingModes: { [k in VideoFacingModeEnum]: k } = {
  environment: "environment",
  left: "left",
  right: "right",
  user: "user",
};

export enum PageLayer {
  ZERO,
  ONE,
  TWO,
}

export enum AppError {
  CAMERA_ACCESS = "Error accessing camera",
  CANVAS_CONTEXT_MISSING = "No canvas context found",
  FORM_FIELD_UNCONNECTED_INPUT = "Attempting to set value on unconnected input element",
  INVALID_OTP = "Invalid OTP provided",
  INVALID_USER_INFO = "Invalid user information",
  INVALID_PRODUCT_INFO = "Invalid product information",
  INVALID_PRODUCT_QR_CODE = "Invalid product QR code",
  INVALID_IMAGE_SELECTED = "Invalid image selected",
  LOGIN_ERROR_NO_EMAIL_GRANTED_FACEBOOK = "Could not retrieve email from facebook",
  LOGIN_ERROR_NO_EMAIL_GRANTED_GOOGLE = "Could not retrieve email from google",
  NO_CHANGE_IN_CONTACT = "Enter a different contact to continue with change",
  NO_IMAGE_CAPTURED = "No image captured",
  NO_PRODUCT_HISTORY = "No product history",
  NO_PRODUCT_OWNER = "Product does not have any owner",
  NO_QR_CODE_DETECTED = "No QR code detected",
  PAGE_LOAD_FAIL = "Page failed to load",
  POLICY_DOC_UNCONNECTED_ELEMENT = "Attempting to modify unconnected policy document",
  UNAUTHORISED_API = "Unauthorised attempt at making api call",
  UNEXPECTED_KEY_CHANGE = "Unexpected offline storage key change",
  VIDEO_ELEMENT_MISSING = "Video element not found",
}

export const Application = {
  get NAME() {
    return AppText.APPLICATION;
  },
  get COMPANY() {
    return AppText.COMPANY;
  },
  DOMAIN_URL(subdomain: string) {
    return `https://${subdomain}.${this.NAME}`.toLowerCase();
  },
  get BASE_APP() {
    return this.DOMAIN_URL("app");
  },
  get BASE_API() {
    return this.DOMAIN_URL("api");
  },
  get Api() {
    const apiUrl = (path: string | TemplateStringsArray) =>
      this.BASE_API + "/" + path;

    return {
      PRODUCTS: apiUrl`products`,
      PRODUCT: apiUrl`product`,
      PROFILE: apiUrl`profile`,
    };
  },
  get BASE_STATE(): AppState {
    const defaultState = Object.freeze({
      account: {
        isAuthenticated: false,
        date: {},
        setting: {
          notice: {
            purchase: {
              confirmation: false,
              request: false,
            },
          },
        },
      },
      page: Page.UN_AUTH,
    });

    const hashState = {};
    try {
      Object.assign(hashState, urlDecode(window.location.hash.substring(1)));
    } catch (e) {
      console.error(e, window.location.hash);
    }

    return merge({}, defaultState, hashState);
  },
} as const;

export enum Currency {
  CAD = "$",
  NGN = "₦",
  USD = "$",
}

enum FacebookUserFields {
  EMAIL = "email",
  NAME = "name",
  PICTURE = "picture",
}

enum FacebookAppScope {
  PUBLIC_PROFILE = "public_profile",
  EMAIL = FacebookUserFields.EMAIL,
}

export const FacebookApp = {
  ID: "390874454094974",
  FIELDS: Object.values(FacebookUserFields).join(","),
  SCOPE: Object.values(FacebookAppScope).join(","),
  User: FacebookUserFields,
} as const;

export const GoogleApp = {
  CLIENT_ID:
    "25650605686-v02faufan7qs7mo0vev2ecqtpu03dpn9.apps.googleusercontent.com",
} as const;
