import React from "react";
import { DimensionPx, Page } from "../utils/constants";
import { mergeStyles } from "../utils/react";
import { AppControls } from "./AppControls";
import { ProductList } from "./ProductList";
import { useOwnedProducts } from "./hooks/useOwnedProducts";

interface ManageProductProps extends React.HTMLProps<HTMLDivElement> {}

/**
 * List of products that the user owns
 */
export function ManageProduct({ style, ...divProps }: ManageProductProps) {
  const wrapperStyle = mergeStyles(style, ManageProduct.wrapperStyle);
  const [ownedProducts, removeProduct] = useOwnedProducts();

  return (
    <div style={wrapperStyle} {...divProps}>
      <ProductList
        items={ownedProducts}
        removeItem={removeProduct}
      />
    </div>
  );
}

ManageProduct.pages = Object.freeze(new Set([Page.PRODUCT_PACKAGES]));
ManageProduct.shouldRenderPage = undefined;
ManageProduct.wrapperStyle = {
  height: `calc(100vh - ${
    DimensionPx.Title.HEIGHT + AppControls.bottomOffsetPx
  }px)`,
  top: DimensionPx.Title.HEIGHT,
} as React.CSSProperties;
