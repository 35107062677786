import React from "react";
import {
  Account,
  AppText,
  DimensionPx,
  FontFamily,
  FontSizeEm,
  Page,
} from "../utils/constants";
import { adjustDate, formatDateValue } from "../utils/product";
import { mergeStyles } from "../utils/react";
import { FormField, FormFieldProps } from "./FormField";
import { PageButton, PageButtonTheme } from "./PageButton";
import { UnAuthOptions } from "./UnAuthOptions";
import { useAccount } from "./hooks/useAccount";
import { useAppPageSetter } from "./hooks/useAppDuck";

interface AccountPersonalDetailsEditProps
  extends React.HTMLProps<HTMLDivElement> {}

export function AccountPersonalDetailsEdit({
  style,
  ...divProps
}: AccountPersonalDetailsEditProps) {
  const setAppPage = useAppPageSetter();
  const [account, updateAccount] = useAccount();
  const [firstName, lastName] = account?.name?.split(" ") ?? [];
  type ValueRef = FormFieldProps["valueRef"]["current"];
  const firstNameValueRef = React.useRef<ValueRef>({});
  const lastNameValueRef = React.useRef<ValueRef>({});
  const dobValueRef = React.useRef<ValueRef>({});

  React.useEffect(() => {
    firstNameValueRef.current?.setValue?.(firstName ?? "");
    lastNameValueRef.current?.setValue?.(lastName ?? "");
    const birthDate = new Date(account?.date?.birth ?? Date.now());
    const formattedBirthDate = formatDateValue(birthDate);
    dobValueRef.current?.setValue?.(formattedBirthDate);
  }, [firstName, lastName, account?.date?.birth]);

  const onValueChange = React.useCallback(() => {
    // console.log("Value needs to be saved to persist");
  }, []);

  const onSave = React.useCallback(() => {
    const accountUpdate: Partial<Account> = { date: {} };
    if (firstNameValueRef.current.isValid && lastNameValueRef.current.isValid) {
      accountUpdate.name = [
        firstNameValueRef.current.value,
        lastNameValueRef.current.value,
      ]
        .filter(Boolean)
        .join(" ");
    }
    if (dobValueRef.current.isValid && dobValueRef.current.value) {
      accountUpdate.date = accountUpdate.date ?? {};
      const inputValue = adjustDate(dobValueRef.current.value);
      accountUpdate.date.birth = new Date(inputValue);
    }
    updateAccount(accountUpdate);
  }, [updateAccount]);

  const wrapperStyle = mergeStyles(
    style,
    AccountPersonalDetailsEdit.wrapperStyle
  );

  return (
    <div style={wrapperStyle} {...divProps}>
      <FormField
        label={AppText.FIRST_NAME}
        onChange={onValueChange}
        onReturn={onSave}
        pattern="\w+$"
        required
        type="text"
        valueRef={firstNameValueRef}
      />
      <FormField
        label={AppText.LAST_NAME}
        onChange={onValueChange}
        onReturn={onSave}
        pattern="\w+$"
        required
        type="text"
        valueRef={lastNameValueRef}
      />
      <FormField
        label={AppText.DATE_OF_BIRTH}
        onChange={onValueChange}
        onReturn={onSave}
        pattern="\d{4}-\d{2}-\d{2}"
        required
        type="date"
        valueRef={dobValueRef}
      />
      <PageButton
        onClick={onSave}
        style={PAGE_BUTTON_STYLES}
        theme={PageButtonTheme.APPROVE}
      >
        {AppText.SAVE}
      </PageButton>
      <PageButton
        onClick={() => setAppPage(Page.ACCOUNT_PERSONAL_DETAILS)}
        style={PAGE_BUTTON_STYLES}
      >
        {AppText.GO_BACK}
      </PageButton>
    </div>
  );
}

/** Pages that this component is displayed for */
AccountPersonalDetailsEdit.pages = Object.freeze(
  new Set([Page.ACCOUNT_PERSONAL_DETAILS_EDIT])
);
AccountPersonalDetailsEdit.shouldRenderPage = undefined;
AccountPersonalDetailsEdit.wrapperStyle = {
  display: "flex",
  height: `calc(100vh - ${DimensionPx.Title.HEIGHT * 2}px)`,
  width: UnAuthOptions.wrapperStyle.width,
} as React.CSSProperties;

const PAGE_BUTTON_STYLES: React.CSSProperties = {
  fontFamily: FontFamily.DEFAULT,
  fontSize: FontSizeEm.REGULAR,
  width: "100%",
} as const;
