import jsqr, { QRCode } from "jsqr";
import { Currency, ProductDetails } from "./constants";
import { prefixWith, suffixWith } from "./string";

export function getProductOwnerId(product?: ProductDetails) {
  return product?.history?.[0]?.owner;
}

export function formatPrice(
  data?: ProductDetails["history"][number]["currency"]
) {
  const currencySymbol = data?.type
    ? { ...Currency }[data.type as string] ?? data.type
    : "";
  return data
    ? `${currencySymbol}${data.fare.toLocaleString(navigator.language)}`
    : null;
}

export function formatDate(date?: Date) {
  // date value is sometimes a string after hydrating state from url
  // TODO: ensure date values are parsed when hydrating state
  return date ? new Date(date).toDateString() : null;
}

/**
 * Format date as YYYY-mm-dd
 */
export function formatDateValue(date: Date) {
  const monthDayPrefix = "00";
  return [
    date.getFullYear(),
    prefixWith(date.getMonth() + 1, monthDayPrefix),
    prefixWith(date.getDate(), monthDayPrefix),
  ].join("-");
}

/** Naive solution to account for timezones */
export function adjustDate(dateString: string) {
  return `${dateString} 12:00:00`;
}

export function formatPhone(n: string) {
  const NUMBER_PLACEHOLDER = "#";
  const getNumberPart = (n: string, start: number, length: number) => {
    const value = n.substring(start, start + length);
    const suffix = new Array(length).fill(NUMBER_PLACEHOLDER).join("");
    return suffixWith(value, suffix);
  };

  const maxPhoneNumberLength = 10;
  const countryCode = n.substring(0, n.length - maxPhoneNumberLength);

  const areaCode = getNumberPart(n, countryCode.length, 3);
  const telPrefix = getNumberPart(n, countryCode.length + 3, 3);
  const lineNumber = getNumberPart(n, countryCode.length + 6, 4);

  return [
    countryCode && `+${countryCode}`,
    `(${areaCode}) ${telPrefix}-${lineNumber}`,
  ]
    .filter(Boolean)
    .join(" ");
}

/**
 * Extracts the product ID from a QR code string is valid otherwise null.
 * @param code - The QR code string.
 */
export function qrCodeToProductId(code: string) {
  try {
    const productUrl = new URL(code);
    return productUrl.searchParams.get("productId");
  } catch (e) {
    return null;
  }
}

/**
 * Enhances image data by adjusting brightness and contrast.
 */
export function enhanceImage(imageData: ImageData) {
  const data = imageData.data;
  for (let i = 0; i < data.length; i += 4) {
    const brightness = 1.5; // Adjust as needed
    const contrast = 1.2; // Adjust as needed

    // Contrast and brightness adjustment
    data[i] = data[i] * contrast + brightness; // Red
    data[i + 1] = data[i + 1] * contrast + brightness; // Green
    data[i + 2] = data[i + 2] * contrast + brightness; // Blue
  }
  return imageData;
}

/**
 * Optimized function to detect multiple QR codes in an image.
 * jsqr has the issue where no qr codes are detected when multiple are present
 * https://github.com/cozmo/jsQR/issues/24
 * @returns {string[]} Array of decoded QR code data.
 */
export async function scanForMultipleQRCodes(imageData: ImageData) {
  const detectedData = new Set<string>();
  // steps through which image data is scanned
  // larger values results in faster scanning but can miss valid codes
  const stepSizePx = 24;
  // { 430px, 873px }
  const scanRegionPx = {
    height: stepSizePx,
    width: stepSizePx,
  };

  const enhancedImageData = enhanceImage(imageData);
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d", { willReadFrequently: true })!;
  canvas.width = enhancedImageData.width;
  canvas.height = enhancedImageData.height;
  ctx.putImageData(enhancedImageData, 0, 0);
  // Scan for QR codes until no more are found
  let qrCode: QRCode | null;

  while (
    scanRegionPx.width < imageData.width ||
    scanRegionPx.height < imageData.height
  ) {
    const imageDataSection = ctx.getImageData(
      0,
      0,
      scanRegionPx.width,
      scanRegionPx.height
    );
    // jsqr cannnot detect multiple qr codes in a single section
    qrCode = jsqr(
      imageDataSection.data,
      imageDataSection.width,
      imageDataSection.height
    );
    if (qrCode) {
      // no guarantee that the detected code is a valid product id
      // but it needs to be stored and cleared so jsqr can detect the next one
      detectedData.add(qrCode.data);
      // This could possibly be clearing out sections of the next valid QR code
      // however only one valid QR code need be detected so that is acceptable
      ctx.clearRect(0, 0, imageDataSection.width, imageDataSection.height);
    }

    scanRegionPx.width = Math.min(
      imageData.width,
      scanRegionPx.width + stepSizePx
    );
    scanRegionPx.height = Math.min(
      imageData.height,
      scanRegionPx.height + stepSizePx
    );
  }

  return Array.from(detectedData);
}
