export function Spacer({
  width = "100%",
  height = "100%",
}: Pick<React.CSSProperties, "height" | "width">) {
  return (
    <div
      style={{
        height,
        maxHeight: height,
        maxWidth: width,
        minHeight: height,
        minWidth: width,
        width,
      }}
    ></div>
  );
}
