export function isInstalled() {
  if (window.matchMedia("(display-mode: standalone)").matches) {
    // PWA is installed and running in standalone mode
    console.log("PWA is installed");
    return true;
  } else {
    if (
      Object.getOwnPropertyDescriptor(window.navigator, "standalone")?.value
    ) {
      // PWA is installed on iOS
      console.log("PWA is installed on iOS");
      return true;
    } else {
      // PWA is not installed or running in normal browser mode
      console.log("PWA is not installed");
      return false;
    }
  }
}
