import React from "react";
import "./Logo.css";
import { asCssTransitions } from "../utils/react";

const cls = (sfx: TemplateStringsArray | string) => ["logo", sfx].join("-");

export default function Logo({
  size,
  ...wrapperProps
}: { size: number } & React.DetailedHTMLProps<
  React.HTMLProps<HTMLDivElement>,
  HTMLDivElement
>) {
  return (
    <div className={cls`wrapper`} {...wrapperProps}>
      <img
        alt="logo"
        className={cls`img`}
        src="logo192.png"
        style={{ transition: asCssTransitions("left", "top", "width") }}
        width={size}
      />
    </div>
  );
}
