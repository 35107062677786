import React from "react";
import { isInstalled } from "../../utils/pwa";
import { useAppErrorSetter } from "./useAppDuck";
import { AppText } from "../../utils/constants";

/** Using the manifest (json) `start_url` detect if launched as installed app */
function isLaunchedAsPwa() {
  const searchParams = new URLSearchParams(window.location.search);
  return searchParams.get("launcher") === "homescreen";
}

export function useInstallPwa() {
  const [pwaInstalled, setIsInstalled] = React.useState(isLaunchedAsPwa());
  const [installEvent, setInstallEvent] =
    React.useState<BeforeInstallPromptEvent>();
  const setAppError = useAppErrorSetter();

  // Prepare install action
  React.useEffect(() => {
    window.addEventListener("beforeinstallprompt", async (event: unknown) => {
      setIsInstalled(isInstalled());
      setInstallEvent(event as BeforeInstallPromptEvent);
    });
  }, []);

  // Trigger install if not already
  const onInstallTrigger = React.useCallback(() => {
    if (pwaInstalled) return;
    if (navigator.userAgent.match(/iPhone|iPad|iPod/)) {
      // As of now, iOS Safari and iOS Chrome do not fully support the "native"
      // PWA install prompt API that is available on Android and other platforms
      setAppError(AppText.PWA_INSTALL_CUSTOM_IOS);
      setIsInstalled(true);
    } else if (installEvent) {
      installEvent.prompt();
    }
  }, [installEvent, pwaInstalled, setAppError]);

  return onInstallTrigger;
}
