import React from "react";
import {
  Color,
  ColorCSS,
  DimensionPx,
  FontFamily,
  FontSizeEm,
  Timing,
} from "../utils/constants";
import { componentKey, mergeStyles } from "../utils/react";
import { prefixWith } from "../utils/string";
import { AccountProfileOtpConfirm } from "./AccountProfileOtpConfirm";
import { AuthCode } from "./AuthCode";
import { useAppState } from "./hooks/useAppDuck";

export function OtpField({
  length,
  onChange,
  onReturn,
}: {
  length: number;
  onChange: (value: string) => void;
  onReturn: () => unknown;
}) {
  const { page } = useAppState();
  const isRenderingForm =
    !!page &&
    (AccountProfileOtpConfirm.pages.has(page) || AuthCode.pages.has(page));
  const [fieldIndex, setFieldIndex] = React.useState(0);

  const fieldRefs = React.useMemo(() => {
    return new Array(length)
      .fill(null)
      .map(() => React.createRef<HTMLInputElement>());
  }, [length]);

  const setOtp = React.useCallback(
    function setOtp(value: string = "", startIdx: number = 0) {
      fieldRefs.slice(startIdx).forEach((ref, i) => {
        if (!ref.current) return;
        ref.current.value = value[i] || "";
      });
      setFieldIndex(Math.min(startIdx + value.length, fieldRefs.length) - 1);
    },
    [fieldRefs]
  );

  const getCurrentValue = React.useCallback(
    () => fieldRefs.map((ref) => ref.current?.value || "").join(""),
    [fieldRefs]
  );

  React.useEffect(() => {
    if (!isRenderingForm) {
      // reset field index
      setFieldIndex(0);
      // reset field values
      setOtp();
      // reset form value
      onChange("");
      return;
    }

    const elementInFocus = document.activeElement;
    const elementToFocus = fieldRefs[fieldIndex]?.current;
    if (!elementToFocus || elementInFocus === elementToFocus) return;

    const currentValue = getCurrentValue();

    setTimeout(
      () => elementToFocus.focus(),
      // TODO: find a better solution for this, maybe using React.useTransition
      Timing.Secs.Transition.DURATION *
        Timing.Millis.ONE_SEC *
        (Number(!currentValue.length) + 0.1)
    );
  }, [
    fieldRefs,
    fieldIndex,
    getCurrentValue,
    onChange,
    isRenderingForm,
    setOtp,
  ]);

  const onValueUpdate = React.useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      setFieldIndex((fieldIndex) => {
        const currentFieldHasValue = !!fieldRefs[fieldIndex].current?.value;
        const newFieldIndex = Math.min(
          length - 1,
          Math.max(0, fieldIndex + (currentFieldHasValue ? 1 : -1))
        );
        if (e.key === "Enter") {
          if (fieldIndex === newFieldIndex) onReturn();
          return fieldIndex;
        } else {
          return newFieldIndex;
        }
      });
      onChange(getCurrentValue());
    },
    [fieldRefs, getCurrentValue, length, onChange, onReturn]
  );

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        gap: DimensionPx.Layout.SPACING_2x,
      }}
    >
      {fieldRefs.map((ref, i) => (
        <OtpCharacterField
          active={fieldIndex === i}
          disabled={getCurrentValue().length < i && !ref.current?.value}
          fieldRef={ref}
          key={componentKey(OtpCharacterField, i)}
          onFocus={() => setFieldIndex(i)}
          onKeyUpCapture={onValueUpdate}
          onPaste={(e) => {
            e.preventDefault();
            setOtp(e.clipboardData?.getData("text"), i);
          }}
        />
      ))}
    </div>
  );
}

function OtpCharacterField({
  active,
  fieldRef,
  onChange,
  style,
  ...props
}: React.HTMLProps<HTMLInputElement> & {
  active: boolean;
  fieldRef?: React.RefObject<HTMLInputElement>;
}) {
  const inputStyle = mergeStyles(style, {
    borderColor:
      ColorCSS[
        props.disabled ? Color.MAIN : active ? Color.HIGHLIGHT : Color.APPROVE
      ],
    borderRadius: DimensionPx.Layout.SPACING_2x,
    borderStyle: "solid",
    borderWidth: DimensionPx.Layout.SPACING_0x,
    caretColor: "transparent",
    cursor: "pointer",
    fontFamily: FontFamily.DEFAULT,
    fontSize: FontSizeEm.LARGE,
    height: DimensionPx.Input.HEIGHT,
    outline: "none",
    pointerEvents: props.disabled ? "none" : "inherit",
    textAlign: "center",
    userSelect: "none",
    width: DimensionPx.Input.HEIGHT * 0.7,
  });

  const onValueChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const singleDigit = Number.parseInt(e.target.value.slice(-1));
      e.target.value = Number.isInteger(singleDigit) ? `${singleDigit}` : "";
      onChange?.(e);
    },
    [onChange]
  );

  const moveCaretToEnd = React.useCallback(() => {
    const field = fieldRef?.current;
    if (!field) return;
    field.setSelectionRange(field.value.length, field.value.length);
  }, [fieldRef]);

  return (
    <input
      onChange={onValueChange}
      onClick={moveCaretToEnd}
      onFocus={moveCaretToEnd}
      pattern="\d{1}"
      ref={fieldRef}
      style={inputStyle}
      type="tel"
      {...props}
    ></input>
  );
}

const OTP_REQUEST_INTERVAL_MS =
  Timing.Millis.ONE_SEC * Timing.Secs.ONE_MIN * 3.01;

export function useCanSendNewOTP(previousOtpDate?: Date) {
  const [result, setResult] = React.useState(canSendNewOTP());
  const sendNewOtp = result[1];
  React.useEffect(() => {
    const timeout = setTimeout(() => {
      setResult(canSendNewOTP(previousOtpDate));
    }, Timing.Millis.ONE_SEC);
    return () => clearTimeout(timeout);
  }, [previousOtpDate, sendNewOtp]);
  return result;
}

export function canSendNewOTP(previousOtpDate?: Date, now: Date = new Date()) {
  let timeToSendNewOtp = 0;
  if (previousOtpDate) {
    const diff = now.getTime() - previousOtpDate.getTime();
    timeToSendNewOtp = Math.max(0, OTP_REQUEST_INTERVAL_MS - diff);
  }
  return [!timeToSendNewOtp, timeToSendNewOtp] as const;
}

export function msToSecs(timeMs: number) {
  const timeSecs = Math.round(timeMs / Timing.Millis.ONE_SEC);
  const minutes = Math.floor(timeSecs / Timing.Secs.ONE_MIN);
  const seconds = timeSecs % Timing.Secs.ONE_MIN;
  return [prefixWith(minutes, "00"), prefixWith(seconds, "00")].join(":") + "s";
}
