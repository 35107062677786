import React from "react";
import { IoIosQrScanner } from "react-icons/io";
import { MdFlipCameraAndroid } from "react-icons/md";
import { PiScanBold } from "react-icons/pi";
import {
  AppError,
  AppText,
  Color,
  ColorCSS,
  DimensionPx,
  Page,
  PageLayer,
  ProductDetails,
} from "../utils/constants";
import { Product } from "../utils/mocks";
import {
  getProductOwnerId,
  qrCodeToProductId,
  scanForMultipleQRCodes,
} from "../utils/product";
import { mergeStyles } from "../utils/react";
import { ButtonIcon } from "./ButtonIcon";
import { CameraStream } from "./CameraStream";
import { useAccount } from "./hooks/useAccount";
import { useAppPageSetter, useAppState } from "./hooks/useAppDuck";
import { useMediaDevices } from "./hooks/useMediaStream";
import { useProductDetails } from "./hooks/useProductDetails";
import { useStateCycle } from "./hooks/useValues";
import { useWindowSize } from "./hooks/useWindowSize";

interface ScanProductProps extends React.HTMLProps<HTMLDivElement> {}

export function ScanProduct({ style, ...divProps }: ScanProductProps) {
  const { page } = useAppState();
  const renderPage = Boolean(page && ScanProduct.pages.has(page));
  const windowSize = useWindowSize();
  const cameraDevices = useMediaDevices({ video: windowSize });
  const [videoTrack, switchVideoTrack] = useStateCycle(
    cameraDevices?.[0],
    cameraDevices
  );
  const appPageSetter = useAppPageSetter();
  const [account] = useAccount();
  const [productDetails, setProductDetails] = useProductDetails();
  const [scanProductId, setScanProductId] = React.useState<string | null>(null);
  const [message, setMessage] = React.useState<string | null>(null);

  const handleScanImage = React.useCallback(
    async function handleScanImage(imageData?: ImageData) {
      let productId: string | null = null;

      if (!renderPage) {
        setMessage(null);
      } else if (!imageData) {
        setMessage(AppError.NO_IMAGE_CAPTURED);
      } else {
        // Pass the image data to jsQR to scan for a QR code
        const qrCodes = await scanForMultipleQRCodes(imageData);

        if (!qrCodes.length) {
          setMessage(AppError.NO_QR_CODE_DETECTED);
        } else {
          productId = qrCodes.map(qrCodeToProductId).find(Boolean) ?? null;
          if (!productId) {
            setMessage(AppError.INVALID_PRODUCT_QR_CODE);
          }
        }
      }

      if (productId) {
        setMessage(AppText.PRODUCT_QR_VALID);
      }

      setScanProductId(productId);
      return productId;
    },
    [renderPage]
  );

  const cameraStreamRef =
    React.useRef<
      React.ComponentProps<typeof CameraStream>["controlsRef"]["current"]
    >();

  const openScannedProduct = React.useCallback(
    function openScannedProduct() {
      const productId =
        scanProductId ??
        handleScanImage(cameraStreamRef.current?.captureImage() ?? undefined) ??
        Product.id; // TODO: remove mock product id

      setProductDetails({ id: productId } as ProductDetails);
    },
    [handleScanImage, scanProductId, setProductDetails]
  );

  React.useEffect(() => {
    if (!scanProductId) return;
    const autoOpenValidProductDelayMs = 100;
    setTimeout(openScannedProduct, autoOpenValidProductDelayMs);
  }, [openScannedProduct, scanProductId]);

  const productOwner = getProductOwnerId(productDetails);
  React.useEffect(() => {
    // should only auto change page when detecting product details on scan page
    if (!productDetails?.id) {
      return;
    }
    // product owners view the details on the manage product package page
    const productIsOwned = account?.id === productOwner;
    const allowedPages = [Page.PRODUCT_SEARCH, Page.PRODUCT_PACKAGES];
    // Only switch page if not already on one of the product details pages
    if (renderPage && page && !allowedPages.includes(page)) {
      const targetPage = allowedPages[Number(productIsOwned)];
      appPageSetter(targetPage);
    }
  }, [
    account?.id,
    appPageSetter,
    page,
    productDetails?.id,
    productOwner,
    renderPage,
  ]);

  const wrapperStyle = mergeStyles(ScanProduct.wrapperStyle, style);

  const hasNoCameras = cameraDevices.length < 1;

  React.useEffect(() => {
    if (renderPage && hasNoCameras) {
      setMessage(AppText.NO_CAMERAS);
    }
  }, [renderPage, hasNoCameras]);

  const messageColor = MessageColor[message as keyof typeof MessageColor];

  const videoStreamOverlay = (
    <IoIosQrScanner
      color={ColorCSS[messageColor ?? Color.FOREGROUND]}
      size={Math.min(windowSize.height, windowSize.width) / 1.2}
      style={{ zIndex: PageLayer.TWO, opacity: 0.5 }}
    />
  );

  const videoCameraProps: React.ComponentProps<typeof CameraStream> = {
    controlsRef: cameraStreamRef,
    onClick: openScannedProduct,
    onErrorMessage: (msg) => void setMessage(msg),
    onScanProduct: handleScanImage,
    overlay: videoStreamOverlay,
    ...windowSize,
    ...videoTrack,
  };

  return (
    <div style={wrapperStyle} {...divProps}>
      <CameraStream {...videoCameraProps} />
      <div style={CameraControlStyle}>
        <ButtonIcon onClick={switchVideoTrack} style={ButtonIconStyle}>
          <MdFlipCameraAndroid
            size={buttonIconSizePx}
            opacity={cameraDevices.length > 1 ? 1 : 0.5}
          />
        </ButtonIcon>
        <span
          onClick={openScannedProduct}
          style={{
            alignItems: "center",
            color: ColorCSS[Color.BACKGROUND],
            display: "flex",
            filter: "invert(1)",
            flexGrow: 1,
            fontWeight: "bold",
            height: ButtonIconStyle.width,
            justifyContent: "center",
            mixBlendMode: "difference",
          }}
        >
          {message ?? AppError.NO_QR_CODE_DETECTED}
        </span>
        <ButtonIcon onClick={openScannedProduct} style={ButtonIconStyle}>
          <PiScanBold
            size={buttonIconSizePx}
            opacity={scanProductId ? 1 : 0.5}
            color={ColorCSS[messageColor ?? Color.BACKGROUND]}
          />
        </ButtonIcon>
      </div>
    </div>
  );
}

/** Pages that this component is displayed for */
ScanProduct.pages = Object.freeze(new Set([Page.PRODUCT_SCAN]));
ScanProduct.shouldRenderPage = undefined;
ScanProduct.wrapperStyle = {} as React.CSSProperties;
const CameraControlStyle: React.CSSProperties = {
  bottom: DimensionPx.Layout.SPACING_2x,
  display: "flex",
  justifyContent: "space-between",
  position: "absolute",
  width: "100%",
};
const buttonIconSizePx = DimensionPx.Button.ICON;
const ButtonIconStyle: React.CSSProperties = {
  aspectRatio: "1/1",
  backgroundColor: ColorCSS[Color.FOREGROUND],
  borderRadius: DimensionPx.Button.PADDING / 2,
  bottom: DimensionPx.Layout.SPACING_2x,
  width: buttonIconSizePx + DimensionPx.Button.PADDING,
};
const MessageColor = {
  [AppError.INVALID_PRODUCT_INFO]: Color.ALERT,
  [AppError.INVALID_PRODUCT_QR_CODE]: Color.ALERT,
  [AppText.PRODUCT_QR_VALID]: Color.APPROVE,
} as const;
