import { useGoogleLogin } from "@react-oauth/google";
import React from "react";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { FaCamera, FaFacebook, FaUserCircle } from "react-icons/fa";
import { FcGoogle } from "react-icons/fc";
import { apiFetch } from "../utils/apiFetch";
import {
  AppError,
  AppText,
  DimensionPx,
  FacebookApp,
  FontFamily,
  FontSizeEm,
  Page,
} from "../utils/constants";
import { mergeStyles } from "../utils/react";
import { AcceptPolicy } from "./AcceptPolicy";
import { AppControls } from "./AppControls";
import { ButtonIcon } from "./ButtonIcon";
import { PageButton, PageButtonTheme } from "./PageButton";
import { Title } from "./Title";
import { useAppErrorSetter, useAppPageSetter } from "./hooks/useAppDuck";
import { useAuthFlow } from "./hooks/useAuthFlow";
import { useInstallPwa } from "./hooks/useInstallPwa";

interface UnAuthOptionsProps extends React.HTMLProps<HTMLDivElement> {}

export function UnAuthOptions({ style, ...divProps }: UnAuthOptionsProps) {
  const setAppPage = useAppPageSetter();
  const { setUserEmail } = useAuthFlow();
  const setAppError = useAppErrorSetter();

  const showEmailSendOTPForm = React.useCallback(
    () => void setAppPage(Page.AUTH_FORM),
    [setAppPage]
  );

  const handleSetUserEmail = React.useCallback(
    (email: string) => {
      setUserEmail(email);
      showEmailSendOTPForm();
    },
    [showEmailSendOTPForm, setUserEmail]
  );

  const onSocialAuthError = (type: string, e: unknown, message?: string) => {
    const toastMessage =
      message ?? (typeof e === "string" ? e : JSON.stringify(e));
    console.error(`error [${type}]`, toastMessage);
    if (toastMessage) setAppError(toastMessage);
  };

  const googleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      // TODO: use google profile image if available
      try {
        const response = await apiFetch<GoogleUserInfo>({
          token: tokenResponse.access_token,
          url: "https://www.googleapis.com/oauth2/v3/userinfo",
        });
        if (response.email && response.email_verified) {
          handleSetUserEmail(response.email);
        } else {
          throw new Error(AppError.LOGIN_ERROR_NO_EMAIL_GRANTED_GOOGLE);
        }
      } catch (err) {
        onSocialAuthError("google", err);
      }
    },
    onNonOAuthError: (err) => {
      onSocialAuthError(
        "basic",
        err,
        AppError.LOGIN_ERROR_NO_EMAIL_GRANTED_GOOGLE
      );
    },
    onError: (err) => {
      onSocialAuthError(
        "oauth",
        err,
        AppError.LOGIN_ERROR_NO_EMAIL_GRANTED_GOOGLE
      );
    },
  });

  const onInstallPwa = useInstallPwa();

  const wrapperStyle = mergeStyles(style, UnAuthOptions.wrapperStyle);

  return (
    <div style={wrapperStyle} {...divProps}>
      <Title>{AppText.WELCOME}</Title>
      <span>{AppText.PLEASE_USE_ACCOUNT}</span>
      <FacebookLogin
        textButton={AppText.AUTH_BTN_FACEBOOK}
        appId={FacebookApp.ID}
        fields={FacebookApp.FIELDS}
        scope={FacebookApp.SCOPE}
        disableMobileRedirect
        callback={(response) => {
          // TODO: use facebook profile image if available
          if (FacebookApp.User.EMAIL in response && response.email) {
            handleSetUserEmail(response.email);
          } else {
            const responseStatus = `${
              Object.getOwnPropertyDescriptor(response, "status")?.value
            }`;
            onSocialAuthError(
              "facebook",
              responseStatus === "unknown"
                ? AppError.LOGIN_ERROR_NO_EMAIL_GRANTED_FACEBOOK
                : responseStatus
            );
          }
        }}
        render={({ onClick }) => {
          return (
            <PageButton
              onClick={() => {
                onInstallPwa();
                onClick();
              }}
              style={PAGE_BUTTON_STYLES}
              theme={PageButtonTheme.NOTICE}
            >
              <ButtonIcon>
                <FaFacebook />
              </ButtonIcon>
              {AppText.AUTH_BTN_FACEBOOK}
            </PageButton>
          );
        }}
      />
      <PageButton
        onClick={() => {
          onInstallPwa();
          googleLogin();
        }}
        style={PAGE_BUTTON_STYLES}
        theme={PageButtonTheme.GOOGLE}
      >
        <ButtonIcon>
          <FcGoogle />
        </ButtonIcon>
        {AppText.AUTH_BTN_GOOGLE}
      </PageButton>
      <PageButton
        onClick={() => {
          onInstallPwa();
          showEmailSendOTPForm();
        }}
        style={PAGE_BUTTON_STYLES}
        theme={PageButtonTheme.APPROVE}
      >
        <ButtonIcon>
          <FaUserCircle />
        </ButtonIcon>
        {AppText.AUTH_BTN}
      </PageButton>
      <span>{AppText.NO_ACCOUNT_NEEDED}</span>
      <PageButton
        onClick={() => {
          onInstallPwa();
          setAppPage(Page.PRODUCT_SCAN);
        }}
        style={PAGE_BUTTON_STYLES}
      >
        <ButtonIcon>
          <FaCamera />
        </ButtonIcon>
        {AppText.PRODUCT_SCAN}
      </PageButton>
      <AcceptPolicy />
    </div>
  );
}

/** Pages that this component is displayed for */
UnAuthOptions.pages = Object.freeze(new Set([Page.UN_AUTH]));
UnAuthOptions.shouldRenderPage = undefined;
UnAuthOptions.wrapperStyle = {
  top: AppControls.bottomOffsetPx,
  width: `calc(100vw - ${DimensionPx.Layout.SPACING_3x * 2}px)`,
} as React.CSSProperties;

const PAGE_BUTTON_STYLES: React.CSSProperties = {
  fontFamily: FontFamily.DEFAULT,
  fontSize: FontSizeEm.REGULAR,
  width: "100%",
} as const;
