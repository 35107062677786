import { capitalize, noop } from "lodash";
import React from "react";
import {
  AppError,
  AppText,
  DimensionPx,
  FontFamily,
  FontSizeEm,
  Page,
  Pattern,
} from "../utils/constants";
import { mergeStyles } from "../utils/react";
import { mask } from "../utils/string";
import { FormField, FormFieldProps } from "./FormField";
import { PageButton, PageButtonTheme } from "./PageButton";
import { Title } from "./Title";
import { UnAuthOptions } from "./UnAuthOptions";
import { useAccount } from "./hooks/useAccount";
import { useAppErrorSetter, useAppPageSetter } from "./hooks/useAppDuck";

interface AccountProfileEditContactProps
  extends React.HTMLProps<HTMLDivElement> {}

export function AccountProfileEditContact({
  style,
  ...divProps
}: AccountProfileEditContactProps) {
  const setAppPage = useAppPageSetter();
  const setAppError = useAppErrorSetter();
  const [account, updateAccount] = useAccount();
  const isEmailChange = !!account?.pendingEmail;
  type ValueRef = FormFieldProps["valueRef"]["current"];
  const contactValueRef = React.useRef<ValueRef>({});

  const onValueChange = noop;

  const goToOtpPage = React.useCallback(() => {
    if (!contactValueRef.current.isValid) return;
    const oldValue = isEmailChange ? account?.email : account?.phone;
    const newValue = contactValueRef.current.value;
    if (newValue === oldValue) {
      setAppError(AppError.NO_CHANGE_IN_CONTACT);
      return;
    }
    updateAccount({
      pendingEmail: isEmailChange ? newValue : "",
      pendingPhone: !isEmailChange ? newValue : "",
    });
    setAppPage(Page.ACCOUNT_PROFILE_OTP_CONFIRM);
    contactValueRef.current.setValue?.("");
  }, [
    account?.email,
    account?.phone,
    isEmailChange,
    setAppError,
    setAppPage,
    updateAccount,
  ]);

  const wrapperStyle = mergeStyles(
    style,
    AccountProfileEditContact.wrapperStyle
  );

  return (
    <div style={wrapperStyle} {...divProps}>
      <Title>
        {isEmailChange ? AppText.CHANGE_EMAIL : AppText.CHANGE_PHONE}
      </Title>
      <div style={{ textAlign: "center" }}>
        {isEmailChange
          ? AppText.CHANGE_EMAIL_INSTRUCTIONS
          : AppText.CHANGE_PHONE_INSTRUCTIONS}
        {": "}
        {mask(isEmailChange ? account?.email : account?.phone)}
      </div>
      <FormField
        label={capitalize(
          isEmailChange ? AppText.PROFILE_EMAIL : AppText.PROFILE_PHONE
        )}
        onChange={onValueChange}
        onReturn={goToOtpPage}
        pattern={isEmailChange ? Pattern.EMAIL : Pattern.PHONE}
        required
        type={isEmailChange ? "text" : "tel"}
        valueRef={contactValueRef}
      />
      <PageButton
        onClick={goToOtpPage}
        style={PAGE_BUTTON_STYLES}
        theme={PageButtonTheme.APPROVE}
      >
        {isEmailChange ? AppText.SEND_OTP_EMAIL : AppText.SEND_OTP_PHONE}
      </PageButton>
      <PageButton
        onClick={() => setAppPage(Page.ACCOUNT_PERSONAL_DETAILS)}
        style={PAGE_BUTTON_STYLES}
      >
        {AppText.GO_BACK}
      </PageButton>
    </div>
  );
}

/** Pages that this component is displayed for */
AccountProfileEditContact.pages = Object.freeze(
  new Set([Page.ACCOUNT_PROFILE_EDIT_CONTACT])
);
AccountProfileEditContact.shouldRenderPage = undefined;
AccountProfileEditContact.wrapperStyle = {
  display: "flex",
  height: `calc(100vh - ${DimensionPx.Title.HEIGHT * 2}px)`,
  width: UnAuthOptions.wrapperStyle.width,
} as React.CSSProperties;

const PAGE_BUTTON_STYLES: React.CSSProperties = {
  fontFamily: FontFamily.DEFAULT,
  fontSize: FontSizeEm.REGULAR,
  width: "100%",
} as const;
